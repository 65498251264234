<template>
  <main class="container">
    <!--ALERT NOTIFICATION-->
    <div class="toaster show">
      <div
        class="toaster__container"
        v-if="alert.show"
        v-bind:class="{
          'toaster--success': alert.status === 'success',
          'toaster--error': alert.status === 'fail',
        }"
      >
        <div class="text-right">
          <span class="fa fa-times" @click="alert.show = false"></span>
        </div>
        <div>
          <span class="d-block text--small text--600 text--white">{{
            alert.title
          }}</span>
          <span class="d-block text--tiny text--400 text--white">{{
            alert.description
          }}</span>
        </div>
      </div>
    </div>
    <!--CONFIRM DIALOG-->
    <div
      class="modal modal__confirm show"
      id="confirmModal"
      tabindex=""
      role="dialog"
      aria-labelledby="modalLabel"
      v-bind:style="{ display: confirm ? 'block' : 'none' }"
      aria-hidden="true"
    >
      <div class="w-100 h-100 d-flex">
        <div class="modal__dialog modal__dialog_alert" role="document">
          <div class="modal__header mb-3">
            <div class="d-flex align-items-center justify-content-between">
              <span class="modal__header__title text--capital">
                confirmation
              </span>
              <a
                class="modal__close modal__close--white d-flex align-items-center justify-content-between"
                @click="confirmSave()"
              >
                <span class="text--capital text--regular"> close </span>
                <ion-icon name="close-outline"></ion-icon>
              </a>
            </div>
            <span class="modal__header__subtitle text--capital">
              Edit Loan Account
            </span>
          </div>
          <div class="modal__content">
            <div class="modal__body">
              <h6
                class="modal__head__subtitle text--capital mb-3"
                v-if="this.disbursementDetails.bankAccountName == null"
              >
                Account Name could not be verified!
              </h6>

              <h5 class="modal__head__subtitled text--capital mb-3">
                Are you sure you want to proceed?
              </h5>
              <p class="form__label"></p>
              <div class="d-flex mt-5">
                <button
                  data-dismiss="#confirmModal"
                  class="button button--lg modal__confirm__button modal__confirm__button--cancel mr-2"
                  @click="confirmSave()"
                >
                  cancel
                </button>
                <button
                  data-dismiss="#confirmModal"
                  class="button button--lg modal__confirm__button modal__confirm__button--delete ml-2 save-changes"
                  @click="save()"
                >
                  {{ process ? "Saving..." : "Save" }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--CUSTOM FIELD MODAL-->
    <AddCustomFieldModal
      :customFieldSet="uiProps.customFieldSet"
      @update="updateCustomFieldDefaultState"
    />

    <button
      data-modal="#insertCustomFieldModal"
      ref="cfmodal"
      style="visibility: hidden"
    >
      click
    </button>

    <!--EDIT LOAN ACCOUNT PAGE-->
    <div class="row justify-content-center">
      <div class="col-8 mb-5">
        <h5 class="modal__head__title text--capital mb-5">Edit Loan Account</h5>
        <form>
          <div class="form__item">
            <label class="form__label text--regular text--black"
              >Account Recipient</label
            >
            <h2
              class="text--capital text--large text--primary"
              v-if="whatPage === 'customer'"
            >
              {{ accountRecipient }}
            </h2>
            <template v-else>
              <multiselect
                v-model="form.selectedCustomer"
                id="ajax"
                label="customerNameWithID"
                track-by="customerKey"
                placeholder="Type to search for client by name"
                open-direction="bottom"
                :options="customers"
                :multiple="false"
                :searchable="true"
                :loading="loadingCustomers"
                :internal-search="true"
                :clear-on-select="false"
                :close-on-select="true"
                :options-limit="300"
                :limit="3"
                :max-height="600"
                :show-no-results="false"
                :hide-selected="true"
                @search-change="searchCustomersSingle"
              >
                <template slot="tag" slot-scope="{ option }"
                  ><span class="custom__tag"
                    ><span>{{ option.customerNameWithID }}</span></span
                  ></template
                >
                <span slot="noResult">Oops! No result found.</span>
              </multiselect>
              <span
                class="error--text text--tiny"
                v-if="$v.form.$dirty && !$v.form.selectedCustomer.required"
              >
                Customer is required
              </span>
            </template>
          </div>
          <div class="row">
            <div class="col-6">
              <div class="form__item">
                <label class="form__label">Product</label>
                <p class="text--regular text--black">
                  {{ loanProductName }}
                </p>
              </div>
            </div>
            <div class="col-6">
              <div class="form__item">
                <label class="form__label">Display Name</label>
                <input
                  type="text"
                  class="input form__input form__input--lg"
                  placeholder="Enter Display Name"
                  v-model="loanName"
                  :disabled="isAccountActive && isAccountIsPending"
                />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-6">
              <div class="form__item">
                <label class="form__label">Product Type</label>
                <p class="text--black text--600 text--capital text--regular">
                  {{ loanProductType }}
                </p>
                <p class="text--black text--500 text--small">
                  {{ loanProductDescription }}
                </p>
              </div>
            </div>
            <!-- <div class="col-6">
              <div class="form__item">
                <label class="form__label">Employer</label>
                <multiselect
                  v-model="employer"
                  id="employer"
                  label="name"
                  track-by="employerKey"
                  placeholder="Select an employer"
                  selectLabel=""
                  open-direction="bottom"
                  :options="sortedEmployers"
                  :searchable="true"
                  :internal-search="true"
                  :clear-on-select="false"
                  :close-on-select="true"
                  :options-limit="300"
                  :limit="7"
                  :max-height="800"
                  :loading="loading"
                  :preselect-first="false"
                  @input="updateEmployer"
                  v-if="isAccountInPartialApplication"
                >
                  <template slot="tag" slot-scope="{ option }"
                    ><span class="custom__tag"
                      ><span>{{ option.name }}</span></span
                    ></template
                  >
                  <span slot="noResult">Oops! No result found.</span>
                </multiselect>
                <select
                  v-else
                  class="select select--lg"
                  v-model="employerKey"
                  :disabled="!isAccountInPartialApplication"
                >
                  <option value="" disabled selected>Select</option>
                  <option
                    v-for="item in sortedEmployers"
                    :key="item.employerKey"
                    :value="item.employerKey"
                  >
                    {{ item.name }}
                  </option>
                </select>
              </div>
            </div> -->
          </div>
          <ul id="accordion">
            <!--ACCOUNT TERMS-->
            <div class="modal__accordion">
              <li
                data-toggle="collapse"
                class="sidebar-right__item modal__accordion--toggle"
                data-target="#accountSettings"
                aria-expanded="false"
                aria-controls="accountSettings"
                ref="accountSettings"
              >
                <h5
                  class="w-100 mb-0 d-flex align-items-center justify-content-between"
                >
                  account terms
                  <span
                    class="text-info text--tiny"
                    v-if="
                      !$v.repaymentPeriodUnit.required ||
                      !$v.repaymentPeriodCount.required ||
                      !$v.loanAmount.required ||
                      !$v.repaymentInstallments.required ||
                      !$v.arrearsTolerancePeriod.required ||
                      !$v.arrearsToleranceAmount.required ||
                      !$v.interestRate.required ||
                      !$v.payOffRate.required
                    "
                  >
                    Fill all information below
                  </span>
                  <ion-icon name="chevron-down-outline"></ion-icon>
                </h5>
              </li>
              <div
                id="accountSettings"
                class="collapse"
                data-parent="#accordion"
              >
                <div class="pt-5">
                  <div class="row">
                    <div class="col-6">
                      <div class="form__item">
                        <label class="form__label">Loan Amount</label>
                        <div
                          class="error--text"
                          v-if="!uiProps.errors.loanAmount.valid"
                        >
                          {{ uiProps.errors.loanAmount.message }}
                        </div>
                        <input
                          type="text"
                          :min="minLoanAmount ? minLoanAmount : 0"
                          :max="maxLoanAmount ? maxLoanAmount : null"
                          class="input input--right form__input form__input--lg"
                          :class="{
                            'input-error':
                              !$v.loanAmount.required ||
                              !uiProps.errors.loanAmount.valid ||
                              numberIsValid(formattedLoanAmount),
                          }"
                          placeholder="Enter Loan Amount"
                          :disabled="!isAccountInPartialApplication && !isAccountIsPending"
                          :value="formattedLoanAmount"
                          @input="numberIsValid(formattedLoanAmount)"
                          @keyup.enter="fomartAndValidateLoanAmount($event)"
                          @blur="
                            controlValue(
                              'loanAmount',
                              loanAmount,
                              minLoanAmount,
                              maxLoanAmount,
                              $event
                            )
                          "
                        />
                        <small v-if="minLoanAmount > 0 || maxLoanAmount > 0"
                          >Min: {{ toNaira(minLoanAmount) }} Max:
                          {{ toNaira(maxLoanAmount) }}</small
                        >
                        <div class="error--text" v-if="!$v.loanAmount.required">
                          Loan Amount is required
                        </div>
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="form__item">
                        <label class="form__label">Interest Rate</label>
                        <div class="input-group">
                          <div class="input-group-prepend">
                            <span
                              class="w-100 d-flex text--white text--600 text--medium align-items-center justify-content-center"
                            >
                              %
                            </span>
                          </div>
                          <input
                            type="text"
                            class="input input--right form__input form__input--lg"
                            :class="{
                              'input-error': !$v.interestRate.required,
                            }"
                            :min="loanMinInterestRate"
                            :max="loanMaxInterestRate"
                            placeholder="Enter interest rate per month"
                            v-model.number="interestRate"
                            :disabled="
                              !isAccountInPartialApplication &&
                              !isAccountIsPending
                            "
                            @input="
                              controlValue(
                                'interestRate',
                                interestRate,
                                loanMinInterestRate,
                                loanMaxInterestRate,
                                $event
                              )
                            "
                          />
                        </div>
                        <small
                          v-if="
                            loanMinInterestRate > 0 || loanMaxInterestRate > 0
                          "
                          >Min: {{ loanMinInterestRate }}% Max:
                          {{ loanMaxInterestRate }}%</small
                        >
                        <div
                          class="error--text"
                          v-if="!$v.interestRate.required"
                        >
                          Interest Rate is required
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-4">
                    <label class="form__label">Over how many installment</label>
                    <input
                      type="text"
                      class="input input--right form__input form__input--lg"
                      v-model.number="repaymentInstallments"
                      :class="{
                        'input-error': !$v.repaymentInstallments.required,
                      }"
                      :min="minNumInstallments"
                      :max="maxNumInstallments"
                      :disabled="
                        !isAccountInPartialApplication &&
                        !isAccountIsPending
                      "
                      @input="
                        controlValue(
                          'repaymentInstallments',
                          repaymentInstallments,
                          minNumInstallments,
                          maxNumInstallments,
                          $event
                        )
                      "
                      placeholder="Enter how many installments"
                    />
                    <small
                      v-if="minNumInstallments > 0 && maxNumInstallments > 0"
                      >Min: {{ minNumInstallments }} Max:
                      {{ maxNumInstallments }}</small
                    >
                    <div
                      class="error--text"
                      v-if="!$v.repaymentInstallments.required"
                    >
                      Repayment Installments is required
                    </div>
                  </div>
                  <div class="col-4">
                    <div class="form__item">
                      <label class="form__label"
                        >Arrears Tolerance Period</label
                      >
                      <input
                        type="text"
                        class="input input--right form__input form__input--lg"
                        :min="minTolerancePercentageOfOutsatndingPrincipal"
                        :max="maxTolerancePercentageOfOutsatndingPrincipal"
                        :class="{
                          'input-error': !$v.arrearsTolerancePeriod.required,
                        }"
                        @input="
                          controlValue(
                            'arrearsTolerancePeriod',
                            arrearsTolerancePeriod,
                            minTolerancePeriod,
                            maxTolerancePeriod,
                            $event
                          )
                        "
                        placeholder="Enter number of days"
                        v-model.number="arrearsTolerancePeriod"
                        :disabled="isAccountIsPending"
                      />
                      <small
                        v-if="maxTolerancePeriod > 0 || minTolerancePeriod > 0"
                        >Min: {{ minTolerancePeriod }} days Max:
                        {{ maxTolerancePeriod }} days</small
                      >
                      <!-- <small
                        v-if="
                          minTolerancePercentageOfOutsatndingPrincipal > 0 ||
                          maxTolerancePercentageOfOutsatndingPrincipal > 0
                        "
                      >
                        Min:
                        {{ minTolerancePercentageOfOutsatndingPrincipal }} days
                        Max:
                        {{ maxTolerancePercentageOfOutsatndingPrincipal }}
                        days</small
                      > -->
                      <div
                        class="error--text"
                        v-if="!$v.arrearsTolerancePeriod.required"
                      >
                        Arrears Tolerance Period is required
                      </div>
                    </div>
                  </div>
                  <div class="col-4">
                    <div class="form__item">
                      <label class="form__label"
                        >Arrears Tolerance Amount</label
                      >
                      <div class="input-group">
                        <div class="input-group-prepend">
                          <span
                            class="w-100 d-flex text--white text--600 text--medium align-items-center justify-content-center"
                          >
                            %
                          </span>
                        </div>
                        <input
                          type="text"
                          class="input input--right form__input form__input--lg"
                          :class="{
                            'input-error': !$v.arrearsToleranceAmount.required,
                          }"
                          placeholder="Enter Arrears Tolerance Amount"
                          v-model.number="arrearsToleranceAmount"
                          :disabled="isAccountIsPending"
                          @input="
                            controlValue(
                              'arrearsToleranceAmount',
                              arrearsToleranceAmount,
                              null,
                              null,
                              $event
                            )
                          "
                          required
                        />
                        <small
                          v-if="
                            minTolerancePercentageOfOutsatndingPrincipal > 0 ||
                            maxTolerancePercentageOfOutsatndingPrincipal > 0
                          "
                        >
                          Min:
                          {{ minTolerancePercentageOfOutsatndingPrincipal }}
                          Max:
                          {{ maxTolerancePercentageOfOutsatndingPrincipal }}
                          (% of outstanding principal)</small
                        >
                        <div
                          class="error--text"
                          v-if="!$v.arrearsToleranceAmount.required"
                        >
                          Arrears Tolerance Amount is required
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-4">
                    <div class="form__item">
                      <label class="form__label">PayOff Rate</label>
                      <div class="input-group">
                        <div class="input-group-prepend">
                          <span
                            class="w-100 d-flex text--white text--600 text--medium align-items-center justify-content-center"
                          >
                            %
                          </span>
                        </div>
                        <input
                          type="text"
                          class="input input--right form__input form__input--lg"
                          :class="{
                            'input-error': !$v.payOffRate.required,
                          }"
                          placeholder="PayOff Rate"
                          v-model.number="payOffRate"
                          :disabled="isAccountIsPending"
                          @input="
                            controlValue(
                              'payOffRate',
                              payOffRate,
                              null,
                              null,
                              $event
                            )
                          "
                        />
                        <div class="error--text" v-if="!$v.payOffRate.required">
                          PayOff rate is required
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-6 d-flex justify-content-around">
                    <div class="form__item flex-grow-1 p-2">
                      <label class="form__label"
                        >Repayments Are Made Every
                      </label>
                      <input
                        type="text"
                        class="input input--right form__input form__input--lg"
                        v-bind:placeholder="
                          'Enter number of ' + loanInterestType
                        "
                        v-model.number="repaymentPeriodCount"
                        :disabled="isAccountIsPending"
                        @input="
                          controlValue(
                            'repaymentPeriodCount',
                            repaymentPeriodCount,
                            null,
                            null,
                            $event
                          )
                        "
                      />
                      <div
                        class="error--text"
                        v-if="!$v.repaymentPeriodCount.required"
                      >
                        Repayment Count Unit is required
                      </div>
                    </div>
                    <div class="form__item p-2">
                      <label class="form__label">{{
                        loanInterestType ? loanInterestType : "Select a period"
                      }}</label>
                      <select
                        class="select select--lg"
                        v-model="repaymentPeriodUnit"
                        v-if="!loanInterestType"
                        :disabled="
                          !isAccountInPartialApplication &&
                          !isAccountActive &&
                          !isAccountIsPending
                        "
                      >
                        <option value="" disabled selected>Select</option>
                        <option
                          v-for="item in enums.repaymentAreMadeEvery"
                          :key="item.id"
                          v-bind:value="item.id"
                        >
                          {{ item.name }}
                        </option>
                      </select>
                    </div>
                  </div>

                  <!-- <div class="col-6 d-flex justify-content-around">
                    <div
                      class="form__item flex-grow-1 p-2"
                    >
                      <label class="form__label"
                        >Collect principal every</label
                      >
                      <div
                        class="d-flex justify-content-between align-items-center"
                      >
                        <input
                          type="text"
                          class="input input--right form__input form__input--lg flex-grow-0"
                          :min="0"
                          v-model.number="defaultPrincipalRepaymentInterval"
                        />
                        <span class="flex-grow-1 p-2"> Month</span>
                      </div>
                    </div>
                  </div> -->

                  <div class="col-6 d-flex justify-content-around">
                    <div
                      class="form__item flex-grow-1 p-2"
                      v-if="gracePeriodType > 0"
                    >
                      <label class="form__label"
                        >With a {{ loanGracePeriodTypeLabel }} for</label
                      >
                      <div
                        class="d-flex justify-content-between align-items-center"
                      >
                        <input
                          type="text"
                          class="input input--right form__input form__input--lg flex-grow-0"
                          :min="0"
                          v-model.number="gracePeriod"
                          :disabled="!isAccountInPartialApplication"
                          @input="
                            controlValue(
                              'gracePeriod',
                              gracePeriod,
                              null,
                              null,
                              $event
                            )
                          "
                        />
                        <span class="flex-grow-1 p-2">installments</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="form__item">
                  <label class="form__label">Interest Calculation Method</label>
                  <p class="text--black text--600 text--capital text--regular">
                    {{ loanInterestCalculationMethod }}
                  </p>
                </div>
              </div>
            </div>

            <!-- Charge Fees -->
            <div class="modal__accordion" v-if="loanHasFees">
              <li
                data-toggle="collapse"
                class="sidebar-right__item modal__accordion--toggle"
                data-target="#feesCharged"
                aria-expanded="false"
                aria-controls="disbursement"
                ref="disbursement"
              >
                <h5
                  class="w-100 mb-0 d-flex align-items-center justify-content-between"
                >
                  Charge Fees
                  <ion-icon name="chevron-down-outline"></ion-icon>
                </h5>
              </li>
              <div id="feesCharged" class="collapse" data-parent="#accordion">
                <div class="pt-5">
                  <!-- Required Loan Fee -->
                  <div
                    class="row"
                    v-for="fee in requiredLoanFees"
                    :key="fee.feeKey"
                  >
                    <div class="col-6">
                      <div class="form__item">
                        <select class="select select--lg" :value="fee.feeKey" disabled>
                          <option
                            v-for="item in requiredLoanFees"
                            :key="item.feeKey"
                            v-bind:value="item.feeKey"
                          >
                            {{ item.feeName }}
                          </option>
                        </select>
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="form__item">
                        <input
                          type="text"
                          class="input input--right form__input form__input--lg"
                          :value="inputCurrencyFormat(fee.calculatedFeeAmount)"
                          disabled
                        />
                      </div>
                    </div>

                    <!-- Add fees button -->
                    <div class="form__item" v-if="!getFeeDifference">
                      <button
                        type="button"
                        class="button page__body__header__button button--grey text--capital"
                        @click="addLoanFees(fee)"
                      >
                        <ion-icon
                          name="add-outline"
                          class="text--regular"
                        ></ion-icon>
                        <span class="d-inline-flex ml-2">add</span>
                      </button>
                    </div>
                  </div>

                  <!-- Loan Fee not required -->
                  <div
                    class="row"
                    v-for="(fee, index) in newLoanFees"
                    :key="fee.feeKey"
                  >
                    <div class="col-6">
                      <div class="form__item" v-if="!fee.feeKey">
                        <select class="select select--lg" v-model="fee.feeKey" @change="filterFeesFromLoan($event, index)">
                          <option value="" selected></option>
                          <option
                            v-for="item in filteredLoanFee"
                            :key="item.feeKey"
                            :value="item.feeKey"
                          >
                            {{ item.feeName }}
                          </option>
                        </select>
                      </div>
                      <div class="form__item" v-else>
                        <select class="select select--lg" v-model="fee.feeKey" @change="filterFeesFromLoan($event, index)">
                          <option
                            v-for="newItem in fee.newFees"
                            :key="newItem.feeKey"
                            :value="newItem.feeKey"
                          >
                            {{ newItem.feeName }}
                          </option>
                        </select>
                      </div>
                    </div>
                    <div class="col-6" v-show="fee.feeKey">
                      <div class="form__item">
                        <input
                          type="text"
                          class="input input--right form__input form__input--lg"
                          :value="inputCurrencyFormat(fee.calculatedFeeAmount)"
                          disabled
                        />
                      </div>
                    </div>

                    <!-- Add fees button -->
                    <div class="form__item" v-if="!getFeeDifference">
                      <button
                        type="button"
                        class="button page__body__header__button button--grey text--capital"
                        @click="addLoanFees(fee)"
                      >
                        <ion-icon
                          name="add-outline"
                          class="text--regular"
                        ></ion-icon>
                        <span class="d-inline-flex ml-2">add</span>
                      </button>
                    </div>

                    <!-- Remove fees button -->
                    <div class="form__item ml-3" v-if="totalLoansNotRequired > 0">
                      <button
                        type="button"
                        class="button page__body__header__button button--grey text--capital"
                        @click="removeLoanFees(fee, index)"
                      >
                        <ion-icon
                          name="add-outline"
                          class="text--regular"
                        ></ion-icon>
                        <span class="d-inline-flex ml-2">remove</span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!--DISBURSEMENT DETAILS-->
            <div class="modal__accordion">
              <li
                data-toggle="collapse"
                class="sidebar-right__item modal__accordion--toggle"
                data-target="#disbursement"
                aria-expanded="false"
                aria-controls="disbursement"
                ref="disbursement"
              >
                <h5
                  class="w-100 mb-0 d-flex align-items-center justify-content-between"
                >
                  Disbursement Details
                  <ion-icon name="chevron-down-outline"></ion-icon>
                </h5>
              </li>
              <div id="disbursement" class="collapse" data-parent="#accordion">
                <div class="pt-5">
                  <div class="row">
                    <div class="col-6">
                      <div class="form__item">
                        <label class="form__label">Expected Disbursement</label>
                        <input
                          v-if="account_details.accountState == 'ACTIVE'"
                          :value="
                            disbursementDetails.expectedDisbursementDate
                              ? new Date(
                                  disbursementDetails.expectedDisbursementDate
                                )
                                  .toISOString()
                                  .slice(0, 10)
                              : ''
                          "
                          name="expectedDisbursementDate"
                          type="date"
                          class="input input--right form__input form__input--lg"
                          :disabled="
                            !isAccountInPartialApplication &&
                            !isAccountIsPending
                          "
                        />
                        <!-- <input
                          v-else-if="!isAccountInPartialApplication"
                          :value="
                            disbursementDetails.expectedDisbursementDate
                              ? new Date(
                                  disbursementDetails.expectedDisbursementDate
                                )
                                  .toISOString()
                                  .slice(0, 10)
                              : ''
                          "
                          name="expectedDisbursementDate"
                          type="date"
                          class="input input--right form__input form__input--lg"
                          :disabled="
                            !isAccountInPartialApplication &&
                            !isAccountIsPending
                          "
                        /> -->
                        <input
                          v-else
                          v-model="disbursementDetails.expectedDisbursementDate"
                          type="date"
                          class="input input--right form__input form__input--lg"
                          placeholder="Enter number of days"
                          @input="calculateFirstRepaymentDate"
                          :disabled="
                            !isAccountInPartialApplication &&
                            !isAccountIsPending
                          "
                        />
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="form__item">
                        <label class="form__label">First Repayment Date</label>
                        <input
                          v-if="account_details.accountState == 'ACTIVE'"
                          :value="
                            disbursementDetails.expectedDisbursementDate
                              ? new Date(disbursementDetails.firstRepaymentDate)
                                  .toISOString()
                                  .slice(0, 10)
                              : ''
                          "
                          name="firstRepaymentDate"
                          type="date"
                          class="input input--right form__input form__input--lg"
                          :disabled="
                            !isAccountInPartialApplication &&
                            !isAccountIsPending
                          "
                        />
                        <!-- <input
                          v-else-if="!isAccountInPartialApplication"
                          :value="
                            disbursementDetails.firstRepaymentDate
                              ? new Date(disbursementDetails.firstRepaymentDate)
                                  .toISOString()
                                  .slice(0, 10)
                              : ''
                          "
                          name="firstRepaymentDate"
                          type="date"
                          class="input input--right form__input form__input--lg"
                          :disabled="
                            !isAccountInPartialApplication &&
                            !isAccountIsPending
                          "
                        /> -->
                        <input
                          v-else
                          v-model="disbursementDetails.firstRepaymentDate"
                          type="date"
                          class="input input--right form__input form__input--lg"
                          :class="{
                            'input-error': scheduleGenerationError !== null,
                          }"
                          placeholder="Enter Arrears Tolerance Amount"
                          :disabled="
                            !isAccountInPartialApplication &&
                            !isAccountIsPending
                          "
                        />
                        <div
                          class="error--text"
                          v-if="scheduleGenerationError !== null"
                        >
                          {{ scheduleGenerationError }}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-6">
                      <div class="form__item">
                        <label class="form__label">Disbursement Bank</label>
                        <select
                          class="select select--lg"
                          v-model="disbursementDetails.bankKey"
                          :disabled="
                            !isAccountInPartialApplication &&
                            !isAccountIsPending
                          "
                          @change="
                            preselectBankCode(disbursementDetails.bankKey)
                          "
                        >
                          <option value="null" disabled selected>
                            __Select bank__
                          </option>
                          <option
                            v-for="(bank, index) in sortedBanks"
                            :key="index"
                            :value="bank.bankKey"
                          >
                            {{ bank.name }}
                          </option>
                        </select>
                      </div>
                    </div>

                    <div class="col-6">
                      <div class="form__item">
                        <label class="form__label"
                          >Disbursement Account Number</label
                        >
                        <input
                          v-model="disbursementDetails.bankAccountNumber"
                          minlength="10"
                          type="text"
                          class="input input--right form__input form__input--lg"
                          placeholder="Enter Disbursement Account Number"
                          :disabled="
                            !isAccountInPartialApplication &&
                            !isAccountIsPending
                          "
                        />
                      </div>
                    </div>

                    <div class="col-6">
                      <div class="form__item">
                        <div class="form__action">
                          <button
                            id="save-changes"
                            type="button"
                            class="button form__button form__button--lg"
                            @click="validateAccountNumber"
                            :disabled="
                              !isAccountInPartialApplication &&
                              !isAccountIsPending
                            "
                          >
                            {{ isValidating ? "Validating..." : "Validate" }}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-6">
                      <div class="form__item">
                        <label class="form__label"
                          >Disbursement Account Name</label
                        >
                        <input
                          v-model="disbursementDetails.bankAccountName"
                          type="text"
                          class="input input--right form__input form__input--lg"
                          placeholder=""
                          disabled
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- SCHEDULE PREVIEW-->
            <div
              class="modal__accordion"
              v-if="account_details.accountState != 'ACTIVE'"
            >
              <li
                data-toggle="collapse"
                class="sidebar-right__item modal__accordion--toggle"
                data-target="#schedule"
                aria-expanded="false"
                aria-controls="schedule"
              >
                <h5
                  class="w-100 mb-0 d-flex align-items-center justify-content-between"
                >
                  Schedule Preview
                  <ion-icon name="chevron-down-outline"></ion-icon>
                </h5>
              </li>
              <div id="schedule" class="collapse" data-parent="#accordion">
                <div class="pt-5">
                  <div>
                    <div class="form__item">
                      <label class="form__label">Amount to be disbursed</label>
                      <p
                        class="text--black text--600 text--capital text--regular"
                      >
                        {{ account_details?.loanProductFees?.amountToDisbursed?.toFixed(2) }}
                      </p>
                      <label class="form__label">Amount used for schedule</label>
                      <p
                        class="text--black text--600 text--capital text--regular"
                      >
                        {{ account_details.loanProductFees.amountToUseForSchedule }}
                      </p>
                      <label class="form__label">Expected Closure</label>
                      <p
                        class="text--black text--600 text--capital text--regular"
                      >
                        {{ expectedClosure | moment("DD/MM/YYYY") }}
                      </p>
                      <div class="text-right mt-3">
                        <button
                          class="button button--grey text--600 text--black text--small"
                          @click="generateSchedule"
                          :disabled="loading"
                          type="button"
                        >
                          {{
                            generatingSchedule
                              ? "Calculating..."
                              : "Preview Schedule"
                          }}
                        </button>
                      </div>
                    </div>
                    <table
                      class="customtable w-100"
                      v-if="
                        !generatingSchedule &&
                        repayments &&
                        newRepayments &&
                        newRepayments.length
                      "
                    >
                      <thead>
                        <tr>
                          <th
                            class="table__header__text table__text text--capital text--tiny"
                            style="width: 5%"
                          >
                            #
                          </th>
                          <th
                            scope="col"
                            class="table__header__text table__text text--capital text--tiny"
                          >
                            Installment Date
                          </th>
                          <th
                            scope="col"
                            class="table__header__text table__text text--capital text--tiny"
                          >
                            Principal
                          </th>
                          <th
                            scope="col"
                            class="table__header__text table__text text--capital text--tiny"
                          >
                            Interest
                          </th>
                          <th
                            scope="col"
                            class="table__header__text table__text text--capital text--tiny"
                          >
                            Fees
                          </th>
                          <th
                            scope="col"
                            class="table__header__text table__text text--capital text--tiny"
                          >
                            Payment Due
                          </th>
                          <th
                            scope="col"
                            class="table__header__text table__text text--capital text--tiny"
                          >
                            Total
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(item, index) in newRepayments" :key="index">
                          <td>
                            <span
                              class="table__body__text table__text text--capital text--tiny text--grey"
                              >{{ item.installment }}</span
                            >
                          </td>
                          <td>
                            <span
                              class="table__body__text table__text text--capital text--tiny text--grey"
                              >{{
                                item.installmentDate | moment("DD/MM/YYYY")
                              }}</span
                            >
                          </td>
                          <td>
                            <span
                              class="table__body__text table__text text--capital text--tiny text--grey"
                              >{{ toNaira(item.principal) }}</span
                            >
                          </td>
                          <td>
                            <span
                              class="table__body__text table__text text--capital text--tiny text--grey"
                              >{{ toNaira(item.interest) }}</span
                            >
                          </td>
                          <td>
                            <span
                              class="table__body__text table__text text--capital text--tiny text--grey"
                              >{{ toNaira(item.fee) }}</span
                            >
                          </td>
                          <td>
                            <span
                              class="table__body__text table__text text--capital text--tiny text--grey"
                              >{{ toNaira(item.paymentDue) }}</span
                            >
                          </td>
                          <td>
                            <span
                              class="table__body__text table__text text--capital text--tiny text--grey"
                              >{{
                                toNaira(
                                  item.balance +
                                    item.principal +
                                    item.paymentDue +
                                    item.interest
                                )
                              }}</span
                            >
                          </td>
                        </tr>
                      </tbody>
                      <tbody>
                        <tr>
                          <td class="border-0"></td>
                          <td class="border-0">
                            <span
                              class="table__body__text table__text text--capital text--tiny text--success"
                            >
                              total
                            </span>
                          </td>
                          <td class="border-0">
                            <span
                              class="table__body__text table__text text--capital text--tiny text--success"
                            >
                              {{
                                repayments
                                  ? toNaira(repayments.totalPrincipal)
                                  : "_"
                              }}
                            </span>
                          </td>
                          <td class="border-0">
                            <span
                              class="table__body__text table__text text--capital text--tiny text--success"
                            >
                              {{
                                repayments
                                  ? toNaira(repayments.totalInterest)
                                  : "_"
                              }}
                            </span>
                          </td>
                          <td class="border-0">
                            <span
                              class="table__body__text table__text text--capital text--tiny text--success"
                            >
                              {{ repayments ? repayments.totalFee : "_" }}
                            </span>
                          </td>
                          <td class="border-0">
                            <span
                              class="table__body__text table__text text--capital text--tiny text--success"
                            >
                              {{
                                repayments
                                  ? toNaira(repayments.totalPaymentDue)
                                  : "_"
                              }}
                            </span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <!-- GUARANTORS-->
            <div class="modal__accordion" v-if="isGuarantorsEnabled">
              <li
                data-toggle="collapse"
                class="sidebar-right__item modal__accordion--toggle"
                data-target="#guarantor"
                aria-expanded="false"
                aria-controls="guarantor"
              >
                <h5
                  class="w-100 mb-0 d-flex align-items-center justify-content-between"
                >
                  <div>
                    Guarantors
                    <span class="ml-3" v-if="guarantorsTotalAmount > 0"
                      >({{ toNaira(guarantorsTotalAmount) }})</span
                    >
                  </div>
                  <ion-icon name="chevron-down-outline"></ion-icon>
                </h5>
                <div
                  class="error--text"
                  v-if="!uiProps.errors.guarantees.valid"
                >
                  {{ uiProps.errors.guarantees.message }}
                </div>
              </li>

              <div id="guarantor" class="collapse" data-parent="#accordion">
                <div class="pt-3">
                  <div class="mb-3" v-if="isAccountInPartialApplication">
                    <button
                      type="button"
                      class="button page__body__header__button button--grey text--capital"
                      @click="pushGuarantorForm"
                    >
                      <ion-icon
                        name="add-outline"
                        class="text--regular"
                      ></ion-icon>
                      <span class="d-inline-flex ml-2">add guarantor</span>
                    </button>
                  </div>
                  <div
                    class="row py-3 my-5"
                    style="background: #fafafa"
                    v-for="(item, index) in guarantees"
                    :key="index"
                  >
                    <div class="col-12">
                      <div
                        class="form__item"
                        v-if="isAccountInPartialApplication"
                      >
                        <label class="form__label">Source</label>
                        <template>
                          <multiselect
                            label="customerFullName"
                            track-by="customerKey"
                            placeholder="Type to search for client by name"
                            open-direction="bottom"
                            :options="sortedCustomers"
                            :value="item.source"
                            :multiple="false"
                            :searchable="true"
                            :loading="item.loadingSource"
                            :internal-search="true"
                            :clear-on-select="false"
                            :close-on-select="true"
                            :options-limit="300"
                            :limit="3"
                            :max-height="600"
                            :show-no-results="false"
                            :hide-selected="true"
                            @select="
                              updateGuarantorField($event, index, 'source')
                            "
                          >
                            <template slot="tag" slot-scope="{ option }"
                              ><span class="custom__tag"
                                ><span>{{
                                  option.customerFullName
                                }}</span></span
                              >
                            </template>
                            <span slot="noResult">Oops! No result found.</span>
                          </multiselect>
                        </template>
                      </div>
                      <div class="form__item" v-else>
                        <label class="form__label">Source</label>
                        <select
                          class="select select--lg"
                          name=""
                          id=""
                          disabled
                          v-model="item.source.customerKey"
                        >
                          <option
                            v-for="item in sortedCustomers"
                            :key="item.customerKey"
                            :value="item.customerKey"
                          >
                            {{ item.customerFullName }}
                          </option>
                        </select>
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="form__item">
                        <label class="form__label">Account</label>
                        <select
                          class="select select--lg"
                          :disabled="
                            !isAccountInPartialApplication &&
                            !isAccountActive &&
                            !isAccountIsPending
                          "
                          v-model="item.savingsAccountKey"
                          @change="
                            updateGuarantorField($event, index, 'account')
                          "
                        >
                          <option
                            :value="null"
                            :disabled="!isAccountActive"
                            selected
                            v-if="!item.loadingAccounts && item.source"
                          >
                            Select Account
                          </option>
                          <!-- <option
                            value="null"
                            disabled
                            selected
                            v-if="item.loadingAccounts"
                          >
                            Loading...
                          </option> -->
                          <option
                            :value="null"
                            selected
                            v-if="!item.loadingAccounts && !item.source"
                          >
                            Select a source first
                          </option>
                          <template v-if="item.accountList">
                            <option
                              v-for="(item2, index2) in item.accountList"
                              :key="index2"
                              :value="item2.accountKey"
                            >
                              {{ item2.accountName }}
                            </option>
                          </template>
                        </select>
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="form__item">
                        <label class="form__label">Amount</label>
                        <input
                          :value="item.amount"
                          type="text"
                          class="input input--right form__input form__input--lg"
                          :disabled="
                            !isAccountInPartialApplication &&
                            !isAccountActive &&
                            !isAccountIsPending
                          "
                          @input="updateGuarantorField($event, index, 'amount')"
                        />
                      </div>
                    </div>
                    <div class="col-12">
                      <button
                        type="button"
                        :disabled="
                          !isAccountInPartialApplication &&
                          !isAccountActive &&
                          !isAccountIsPending
                        "
                        class="button ml-auto page__body__header__button button--cancel text--capital"
                        @click="removeGuarantor(index)"
                      >
                        <span class="d-inline-flex">delete</span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- COLLATERAL ASSET-->
            <div class="modal__accordion" v-if="isCollateralEnabled">
              <li
                data-toggle="collapse"
                class="sidebar-right__item modal__accordion--toggle"
                data-target="#collateral"
                aria-expanded="false"
                aria-controls="collateral"
              >
                <h5
                  class="w-100 mb-0 d-flex align-items-center justify-content-between"
                >
                  Collateral Asset
                  <ion-icon name="chevron-down-outline"></ion-icon>
                </h5>
                <div
                  class="error--text"
                  v-if="!uiProps.errors.collateral.valid"
                >
                  {{ uiProps.errors.collateral.message }}
                </div>
              </li>

              <div id="collateral" class="collapse" data-parent="#accordion">
                <div class="pt-5">
                  <div class="row">
                    <!--<div class="col-4">
                      <div class="form__item mb-3">
                        <label class="form__label">Name</label>
                        <input
                          v-model="collateralModel.assetName"
                          type="text"
                          class="input input--right form__input form__input--lg"
                          :class="{
                            'input-error':
                              collateralModel.errorAssetName == true,
                          }"
                          @input="validateCollateral"
                        />
                      </div>
                    </div>
                    <div class="col-4">
                      <div class="form__item mb-3">
                        <label class="form__label">Description</label>
                        <input
                          v-model="collateralModel.description"
                          type="text"
                          class="input input--right form__input form__input--lg"
                          :class="{
                            'input-error':
                              collateralModel.errorDescription == true,
                          }"
                          @input="validateCollateral"
                        />
                      </div>
                    </div>
                    <div class="col-4">
                      <div class="form__item mb-3">
                        <label class="form__label">Amount</label>
                        <input
                          v-model="collateralModel.amount"
                          type="text"
                          class="input input--right form__input form__input--lg"
                          :class="{
                            'input-error': collateralModel.errorAmount == true,
                          }"
                          @input="validateCollateral"
                        />
                      </div>
                    </div>-->
                    <div
                      class="col-12 text-right"
                      v-if="isAccountInPartialApplication"
                    >
                      <button
                        type="button"
                        class="button page__body__header__button button--grey text--capital float-right"
                        @click="addCollateral"
                      >
                        <ion-icon
                          name="add-outline"
                          class="text--regular"
                        ></ion-icon>
                        <span class="d-inline-flex ml-2">add</span>
                      </button>
                    </div>
                  </div>
                  <div
                    class="row py-3 my-5"
                    style="background: #fafafa"
                    v-for="(asset, index) in collateralAssets"
                    :key="index"
                  >
                    <div class="col-4">
                      <div class="form__item">
                        <label class="form__label">Name</label>
                        <input
                          type="text"
                          :value="asset.assetName"
                          class="input input--right form__input form__input--lg"
                          :disabled="
                            !isAccountInPartialApplication &&
                            !isAccountActive &&
                            !isAccountIsPending
                          "
                          @input="
                            updateCollateralInputField(
                              $event,
                              index,
                              'assetName'
                            )
                          "
                        />
                      </div>
                    </div>
                    <!--<div class="col-4">
                      <div class="form__item">
                        <label class="form__label">Description</label>
                        <input
                          type="text"
                          :value="asset.description"
                          class="input input--right form__input form__input--lg"
                          @input="
                            updateCollateralInputField(
                              $event,
                              index,
                              'description'
                            )
                          "
                        />
                      </div>
                    </div>-->
                    <div class="col-4">
                      <div class="form__item">
                        <label class="form__label">Amount</label>
                        <input
                          type="text"
                          :value="asset.amount"
                          class="input input--right form__input form__input--lg"
                          :disabled="
                            !isAccountInPartialApplication &&
                            !isAccountActive &&
                            !isAccountIsPending
                          "
                          @input="
                            updateCollateralInputField($event, index, 'amount')
                          "
                        />
                      </div>
                    </div>
                    <div class="col-12">
                      <button
                        type="button"
                        class="button ml-auto page__body__header__button button--cancel text--capital"
                        :disabled="
                          !isAccountInPartialApplication &&
                          !isAccountActive &&
                          !isAccountIsPending
                        "
                        @click="removeCollateralAsset(index)"
                      >
                        <span class="d-inline-flex">delete</span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- CUSTOM FIELDS -->
            <div
              class="modal__accordion"
              v-for="item in uiProps.customFieldSets"
              :key="item.fieldSetKey"
            >
              <li
                data-toggle="collapse"
                class="sidebar-right__item modal__accordion--toggle"
                :data-target="'#dynamic_' + item.fieldSetKey"
                aria-expanded="false"
                :aria-controls="'dynamic_' + item.fieldSetKey"
              >
                <h5
                  class="w-100 mb-0 d-flex align-items-center justify-content-between text-uppercase"
                >
                  {{ item.fieldSetName }}
                  <ion-icon name="chevron-down-outline"></ion-icon>
                </h5>
              </li>
              <div
                :id="'dynamic_' + item.fieldSetKey"
                class="collapse"
                data-parent="#accordion"
              >
                <div class="pt-5">
                  <!-- <div
                    class="text-right mb-3"
                  >
                    <button
                      type="button"
                      @click="openCustomFieldModal(item.fieldSetKey)"
                      v-if="canAddCustomField(item.fieldSetKey)"
                      class="
                        d-flex
                        align-items-center
                        ml-auto
                        button button--grey
                        text--600 text--black text--small text--capital
                      "
                    >
                      add fields
                      <ion-icon
                        name="add-outline"
                        class="text--600 text--medium ml-2"
                      ></ion-icon>
                    </button>
                  </div> -->
                  <div class="row">
                    <template v-for="item2 in item.fields">
                      <div
                        v-if="
                          item2.isDefault || item2.isRequired || item2.value
                        "
                        class="col-6"
                        :key="item2.fieldKey"
                      >
                        <!--TEXT/NUMBER-->
                        <div
                          class="form__item"
                          v-if="
                            getFieldType(item2.dataType) === 'text' ||
                            getFieldType(item2.dataType) === 'number'
                          "
                        >
                          <label class="form__label"
                            >{{ item2.fieldName }}
                          </label>
                          <div class="d-flex align-items-center">
                            <input
                              type="text"
                              :value="item2.value"
                              class="input input--right form__input form__input--lg"
                              @input="updateCustomField(item2.fieldKey, $event)"
                            />
                            <!-- <button
                              type="button"
                              class="ml5 button button--cancel text--capital"
                              @click="
                                updateCustomFieldDefaultState(
                                  item.fieldSetKey,
                                  item2.fieldKey,
                                  false
                                )
                              "
                            >
                              <span class="d-inline-flex">delete</span>
                            </button> -->
                          </div>
                        </div>
                        <!--DATE-->
                        <div
                          class="form__item"
                          v-else-if="getFieldType(item2.dataType) === 'date'"
                        >
                          <label class="form__label">{{
                            item2.fieldName
                          }}</label>
                          <div class="d-flex align-items-center">
                            <input
                              type="date"
                              class="input input--right form__input form__input--lg"
                              :value="item2.value"
                              @input="updateCustomField(item2.fieldKey, $event)"
                            />
                            <!-- :disabled="!isAccountInPartialApplication" -->
                            <!-- <button
                              type="button"
                              class="ml5 button button--cancel text--capital"
                              
                              @click="
                                updateCustomFieldDefaultState(
                                  item.fieldSetKey,
                                  item2.fieldKey,
                                  false
                                )
                              "
                            >
                              <span class="d-inline-flex">delete</span>
                            </button> -->
                          </div>
                        </div>
                        <!--CHECKBOX-->
                        <div
                          class="form__item"
                          v-else-if="
                            getFieldType(item2.dataType) === 'checkbox'
                          "
                        >
                          <label class="form__label">{{
                            item2.fieldName
                          }}</label>
                          <div class="d-flex align-items-center">
                            <label class="form__label has-checkbox">
                              <input
                                type="checkbox"
                                class="input input--right form__input form__input--lg"
                                @input="
                                  updateCustomField(item2.fieldKey, $event)
                                "
                              />
                              <span class="checkmark"></span>
                            </label>
                            <!-- <button
                              type="button"
                              class="ml5 button button--cancel text--capital"
                              @click="
                                updateCustomFieldDefaultState(
                                  item.fieldSetKey,
                                  item2.fieldKey,
                                  false
                                )
                              "
                            >
                              <span class="d-inline-flex">delete</span>
                            </button> -->
                          </div>
                        </div>
                        <!--SELECT-->
                        <div
                          class="form__item"
                          v-else-if="getFieldType(item2.dataType) === 'select'"
                        >
                          <label class="form__label">{{
                            item2.fieldName
                          }}</label>
                          <div class="d-flex align-items-center">
                            <select
                              class="select select--lg"
                              @change="
                                updateCustomField(item2.fieldKey, $event)
                              "
                            >
                              <option value="null" disabled selected>
                                Select {{ item2.fieldName }}
                              </option>
                              <option
                                v-for="itemSelection in item2.fieldSelections"
                                :key="itemSelection.fieldSelectionKey"
                                v-bind:value="itemSelection.fieldSelectionKey"
                              >
                                {{ itemSelection.value }}
                              </option>
                            </select>
                            <!-- <button
                              type="button"
                              class="ml5 button button--cancel text--capital"
                              @click="
                                updateCustomFieldDefaultState(
                                  item.fieldSetKey,
                                  item2.fieldKey,
                                  false
                                )
                              "
                            >
                              <span class="d-inline-flex">delete</span>
                            </button> -->
                          </div>
                        </div>

                        <!-- multiselect -->
                        <div
                          class="form__item"
                          v-else-if="
                            getFieldType(item2.dataType) === 'user link'
                          "
                        >
                          <label class="form__label">{{
                            item2.fieldName
                          }}</label>
                          <div class="d-flex align-items-center">
                            <multiselect
                              v-model="form.customFieldValue"
                              :id="'cus-' + form.customFieldKey"
                              :ref="form.customFieldKey"
                              label="name"
                              track-by="userKey"
                              placeholder="Type to search"
                              open-direction="bottom"
                              :options="sortedUsers"
                              :multiple="false"
                              :searchable="true"
                              :internal-search="true"
                              :clear-on-select="false"
                              :close-on-select="true"
                              :options-limit="300"
                              :limit="3"
                              :max-height="800"
                              :show-no-results="false"
                              :hide-selected="true"
                              @selected="
                                updateCustomField(item2.fieldKey, $event)
                              "
                            >
                              <template
                                slot="tag"
                                slot-scope="{ option, remove }"
                              >
                                <span class="custom__tag">
                                  <span>{{ option.name }}</span>
                                  <span
                                    class="custom__remove"
                                    @click="remove(option)"
                                    >❌</span
                                  >
                                </span>
                              </template>
                            </multiselect>
                            <!-- <select
                              class="select select--lg"
                              @change="
                                updateCustomField(item2.fieldKey, $event)
                              "
                            >
                              <option value="null" disabled selected>
                                Select {{ item2.fieldName }}
                              </option>
                              <option
                                v-for="itemSelection in item2.fieldSelections"
                                :key="itemSelection.fieldSelectionKey"
                                v-bind:value="itemSelection.fieldSelectionKey"
                              >
                                {{ itemSelection.value }}
                              </option>
                            </select> -->
                            <!-- <button
                              type="button"
                              class="ml5 button button--cancel text--capital"
                              @click="
                                updateCustomFieldDefaultState(
                                  item.fieldSetKey,
                                  item2.fieldKey,
                                  false
                                )
                              "
                            >
                              <span class="d-inline-flex">delete</span>
                            </button> -->
                          </div>
                        </div>
                      </div>
                    </template>
                  </div>
                </div>
              </div>
            </div>

            <!-- ASSOCIATION-->
            <div class="modal__accordion">
              <li
                data-toggle="collapse"
                class="sidebar-right__item modal__accordion--toggle"
                data-target="#association"
                aria-expanded="false"
                aria-controls="association"
              >
                <h5
                  class="w-100 mb-0 d-flex align-items-center justify-content-between"
                >
                  Association
                  <span
                    class="error--text text--tiny"
                    v-if="$v.form.$dirty && !$v.form.assignedBranchKey.required"
                  >
                    Association is required
                  </span>
                  <ion-icon name="chevron-down-outline"></ion-icon>
                </h5>
              </li>

              <div id="association" class="collapse" data-parent="#accordion">
                <div class="pt-5">
                  <div class="row">
                    <div class="col-12">
                      <div class="form__item">
                        <label class="form__label">Branches</label>
                        <select
                          class="select select--lg"
                          required
                          :disabled="
                            !isAccountInPartialApplication &&
                            !isAccountActive &&
                            !isAccountIsPending
                          "
                          v-model="account_details.assignedBranchKey"
                        >
                          <option value="null" disabled>
                            Select Association Branches
                          </option>
                          <option
                            v-for="item in branchList"
                            :key="item.branchKey"
                            v-bind:value="item.branchKey"
                          >
                            {{ item.branchName }}
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-12">
                      <div class="form__item">
                        <label class="form__label">Account Officer</label>
                        <select
                          class="select select--lg"
                          required
                          :disabled="
                            !isAccountInPartialApplication &&
                            !isAccountActive &&
                            !isAccountIsPending
                          "
                          v-if="account_details.accountOfficer !== null"
                          v-model="form.assignedUserKey"
                        >
                          <option value="null" disabled selected>
                            Select Account Officer
                          </option>
                          <option
                            v-for="item in accountOfficers"
                            :key="item.userKey"
                            :value="item.userKey"
                          >
                            {{ item.name }}
                          </option>
                        </select>
                        <select
                          class="select select--lg"
                          required
                          :disabled="
                            !isAccountInPartialApplication &&
                            !isAccountActive &&
                            !isAccountIsPending
                          "
                          v-else
                          v-model="form.assignedUserKey"
                        >
                          <option value="null" disabled selected>
                            Select Account Officer
                          </option>
                          <option
                            v-for="item in accountOfficers"
                            :key="item.userKey"
                            :value="item.userKey"
                          >
                            {{ item.name }}
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!--ACCOUNT NOTES-->
            <div class="modal__accordion">
              <li
                data-toggle="collapse"
                class="sidebar-right__item modal__accordion--toggle"
                data-target="#decriptions"
                aria-expanded="false"
                aria-controls="decriptions"
              >
                <h5
                  class="w-100 mb-0 d-flex align-items-center justify-content-between"
                >
                  Account Notes
                  <ion-icon name="chevron-down-outline"></ion-icon>
                </h5>
              </li>
              <div id="decriptions" class="collapse" data-parent="#accordion">
                <div class="pt-5">
                  <div class="form__item">
                    <textarea
                      cols="5"
                      rows="5"
                      class="input form__input"
                      v-model="notes"
                    ></textarea>
                  </div>
                </div>
              </div>
            </div>
          </ul>
          <div class="form__item mt-5">
            <div class="form__action">
              <button
                id="save-changes"
                type="button"
                class="button form__button form__button--lg float-right"
                @click="confirmSave()"
              >
                {{ process ? "Saving..." : "Save" }}
              </button>
              <router-link
                :to="'/loan/accounts/' + account_details.id"
                tag="a"
                class="button button--cancel form__button form__button--lg float-left"
                >Cancel</router-link
              >
            </div>
          </div>
        </form>
      </div>
    </div>
  </main>
</template>

<script>
import { EditLoanAccountInitialState } from "./config_modules/initialStates";
import { mapState } from "vuex";
import { required } from "vuelidate/lib/validators";
import LoanApiService from "@/core/services/api.loan.service";
import GeneralApiService from "@/core/services/general.service";
import branchService from "@/core/services/organization-service/branches.service";
import { GET_ALL_LOAN_PRODUCTS } from "@/core/services/store/types";
import Multiselect from "vue-multiselect";
import AddCustomFieldModal from "./components/includeCustomFieldModal";
import { GET_LOAN_ACCOUNT_DETAILS } from "../../../core/services/store/types";
import moment from "moment";
import { GET_ACTIVE_BANKS } from "@/core/services/store/banking.module";
import ApiService from "../../../core/services/access.service";
import currencyMixin from "@/mixins/currencyMixin";

export default {
  name: "EditLoanAccountPage",
  mixins: [currencyMixin],
  components: {
    Multiselect,
    AddCustomFieldModal,
  },
  props: {
    show: Boolean,
    close: Function,
  },
  data: function () {
    return EditLoanAccountInitialState();
  },
  validations: {
    loanAmount: { required },
    interestRate: { required },
    repaymentPeriodUnit: { required },
    repaymentPeriodCount: { required },
    repaymentInstallments: { required },
    arrearsTolerancePeriod: { required },
    arrearsToleranceAmount: { required },
    payOffRate: { required },
    form: {
      assignedBranchKey: { required },
    },
  },
  methods: {
    addLoanFees(loan) {
      this.newLoanFees.push({ feeKey: "", calculatedFeeAmount: 0, feeName: loan.feeName });
    },
    convertDateToDay(date) {
      const day = new Date(date);
      const todaysDate = String(day.getDate()).padStart(2, "0");
      const month = String(day.getMonth() + 1).padStart(2, "0");
      const year =  day.getFullYear();
      return `${year}-${month}-${todaysDate}`;
    },
    removeLoanFees(loan, index) {
      const feeWithIdIndex = this.newFees.findIndex((obj) => obj === loan.feeKey);
      this.newFees.splice(feeWithIdIndex, 1);
      this.getFilteredLoans(this.newFees);
      this.newLoanFees.splice(index, 1);
    },
    getFilteredLoans(value) {
      const { loanProductFees } = this.account_details;
      if(value) {
        const filArray = this.loanFeesNotRequired.filter((fee) => fee.isRequired !== true && !value.includes(fee.feeKey));
        this.filteredLoanFee = filArray;
      } else {
        this.filteredLoanFee = loanProductFees.loanProductFees.filter((fee) => fee.isRequired !== true); 
      }
    },
    getLoansNotRequired(value) {
      const { loanProductFees } = this.account_details;
      if(value) {
        const filArray = this.loanFeesNotRequired.filter((fee) => fee.isRequired !== true && !value.includes(fee.feeKey));
        //this.filteredLoanFee = filArray;
        this.newLoanFees = filArray.map(newLoan => (
          {
            ...newLoan,
            newFees: [ newLoan ],
          }
        ));
        this.newFees = filArray.map(loan => loan.feeKey);
      } 
      else {
        //this.filteredLoanFee = loanProductFees.loanProductFees.filter((fee) => fee.isRequired !== true); 
        this.newLoanFees = loanProductFees.loanProductFees.filter((fee) => fee.isRequired !== true);
      }
    },
    storeLoansNotRequireFee(value, index) {
      const newValue = this.loanFeesNotRequired.filter((fee) => fee.isRequired !== true && fee.feeKey === value);
      this.newLoanFees[index].newFees = newValue;
      this.newLoanFees[index].calculatedFeeAmount = this.newLoanFees[index].newFees[0].calculatedFeeAmount;
    },
    filterFeesFromLoan(e, index) {
      const value = e.target.value;
      this.storeLoansNotRequireFee(value, index);
      this.newFees[index] = value;
      this.getFilteredLoans(this.newFees, index);
    },
    numberIsValid(amount) {
      if (!this.isValidNumberInput(amount)) return true;
      return false;
    },
    calculateFirstRepaymentDate() {
      const date = this.disbursementDetails.expectedDisbursementDate;
      const dateNo = this.repaymentPeriodCount;
      let dated = "";
      if (this.loanInterestType === "Days") {
        dated = moment(date, "YYYY-MM-DD").add("days", dateNo);
        let day = dated.format("DD");
        let month = dated.format("MM");
        let year = dated.format("YYYY");
        return (this.disbursementDetails.firstRepaymentDate = `${year}-${month}-${day}`);
      }
      if (this.loanInterestType === "Months") {
        dated = moment(date, "YYYY-MM-DD").add("months", dateNo);
        let day = dated.format("DD");
        let month = dated.format("MM");
        let year = dated.format("YYYY");
        return (this.disbursementDetails.firstRepaymentDate = `${year}-${month}-${day}`);
      }
      dated = moment(date, "YYYY-MM-DD").add("years", dateNo);
      let day = dated.format("DD");
      let month = dated.format("MM");
      let year = dated.format("YYYY");
      this.disbursementDetails.firstRepaymentDate = `${year}-${month}-${day}`;
    },
    fomartAndValidateLoanAmount(amount) {
      const amountValue = amount.target.value;
      const amtPaid = this.convertFigureToNumber(amountValue);
      const amountInputted = this.inputCurrencyFormat(amtPaid);

      if (this.validateDepositAmount(amountValue)) {
        this.loanAmount = amtPaid;
        this.formattedLoanAmount = amountInputted;
        return;
      }
      this.loanAmount = this.loanAmount;
      this.formattedLoanAmount = "";
    },
    updateEmployer() {
      this.employerKey = this.employer.employerKey;
    },
    getAccountOfficer() {
      ApiService.get(
        `ProleanUser/GetBranchClientOfficer?branchKey=${this.account_details.assignedBranchKey}`
      ).then((resp) => {
        this.accountOfficers = resp.data.data;
      });
    },
    validateLoanAmount() {
      if (this.loanAmount < this.minLoanAmount) {
        return true;
      } else if (this.loanAmount > this.maxLoanAmount) {
        // return this.alertError('Loan amount can not be larger than loan amount specified');
        return true;
      }
      return false;
    },
    getAllBanks() {
      this.$store.dispatch(GET_ACTIVE_BANKS);
    },
    getAllEmployers() {
      const paged = {
        pageIndex: 1,
        pageSize: 99999,
      };

      GeneralApiService.post("Employer/Employers", paged)
        .then((res) => res.data.data.items)
        .then((res) => {
          this.allEmployers = res;
        });
    },
    preselectBankCode(key) {
      const bank = this.banks.find((item) => item.bankKey === key);
      this.bankSortedCode = bank.flutterWaveBankCode;
    },
    validateAccountNumber() {
      this.isValidating = true;
      let account = {
        accountNumber: this.disbursementDetails.bankAccountNumber,
        accountBank: this.bankSortedCode,
      };
      setTimeout(
        () =>
          LoanApiService.post("LoanAccount/verify-account", account)
            .then((res) => res.data)
            .then((res) => {
              if (res.status) {
                this.disbursementDetails.bankAccountName =
                  res.data.data.account_name;
                this.isValidating = false;
              }
            })
            .catch((error) => {
              this.alertError(
                error.response.data.message || "Account not found!"
              );
              this.disbursementDetails.bankAccountName = this.nameError;
              this.isValidating = false;
            }),
        800
      );
    },
    validate: function () {
      if (this.isGuarantorsEnabled) {
        if (this.guarantees.length < 1) {
          this.uiProps.errors.guarantees.valid = false;
          this.uiProps.errors.guarantees.message =
            "This Loan Product requires Guarantee";
          return false;
        } else {
          this.uiProps.errors.guarantees.valid = true;
          this.uiProps.errors.guarantees.message = "";
        }
      }
      if (this.isCollateralEnabled) {
        if (this.collateralAssets.length < 1) {
          this.uiProps.errors.collateral.valid = false;
          this.uiProps.errors.collateral.message =
            "This Loan Product requires Collateral";
          return false;
        } else {
          this.uiProps.errors.collateral.valid = true;
          this.uiProps.errors.collateral.message = "";
        }
      }
      //if (this.loanAmount < this.minLoanAmount) {
      //this.uiProps.errors.loanAmount.valid = false;
      //this.uiProps.errors.loanAmount.message =
      //"This Loan cannnot be less than " + this.minLoanAmount;
      //return false;
      //}
      //if (this.loanAmount > this.maxLoanAmount) {
      //this.uiProps.errors.loanAmount.valid = false;
      //this.uiProps.errors.loanAmount.message =
      //"This Loan cannnot be more than " + this.maxLoanAmount;
      // return false;
      //}
      if (this.loanAmount == null) {
        this.uiProps.errors.loanAmount.valid = false;
        this.uiProps.errors.loanAmount.message = "Loan amount can not be null";
      } else {
        this.uiProps.errors.loanAmount.valid = true;
        this.uiProps.errors.loanAmount.message = "";
      }
      return true;
    },
    alertSuccess(description) {
      this.alert.status = "success";
      this.alert.title = "Success";
      this.alert.description = description;
      this.alert.show = true;
      const self = this;
      setTimeout(function () {
        self.alert.show = false;
      }, 15000);
    },
    alertError(description) {
      this.alert.status = "fail";
      this.alert.title = "Error";
      this.alert.description = description;
      this.alert.show = true;
      const self = this;
      setTimeout(function () {
        self.alert.show = false;
      }, 15000);
    },
    toNaira(value) {
      const val = new Intl.NumberFormat().format(value);
      return "₦" + val;
    },
    openCustomFieldModal(id) {
      this.$refs.cfmodal.click();
      this.uiProps.customFieldSet = this.uiProps.customFieldSets.find(
        (item) => item.fieldSetKey == id
      );
    },
    updateCustomFieldDefaultState(
      customFieldSetId,
      customFieldsId,
      state = true
    ) {
      const customFieldSetIndex = this.uiProps.customFieldSets.findIndex(
        (item) => item.fieldSetKey == customFieldSetId
      );
      if (customFieldSetIndex !== -1) {
        const cloneModel = JSON.parse(
          JSON.stringify(this.uiProps.customFieldSets)
        );
        const customFieldIndex = this.uiProps.customFieldSets[
          customFieldSetIndex
        ].fields.findIndex((item) => item.fieldKey == customFieldsId);
        if (customFieldIndex !== -1) {
          cloneModel[customFieldSetIndex].fields[customFieldIndex].isDefault =
            state;
          this.uiProps.customFieldSets = cloneModel;
        }
      }
    },
    updateCustomField(fieldId, event) {
      const fieldIndex = this.form.customInformation.findIndex(
        (item) => item.customFieldID == fieldId
      );
      if (fieldIndex !== -1) {
        this.form.customInformation[fieldIndex] = {
          value: event.target.value || event.target.value.userKey,
          customFieldID: fieldId,
        };
      } else {
        this.form.customInformation.push({
          value: event.target.value,
          customFieldID: fieldId,
        });
      }
    },
    canAddCustomField(id) {
      const can = this.uiProps.customFieldSets
        .find((item) => item.fieldSetKey === id)
        .fields.findIndex((item) => item.isDefault === false);
      if (can !== -1) {
        return true;
      } else {
        return false;
      }
    },
    getFieldType(type) {
      if (type.indexOf("Number") !== -1) {
        return "number";
      } else if (type.indexOf("FreeText") !== -1) {
        return "text";
      } else if (type.indexOf("Selection") !== -1) {
        return "select";
      } else if (type.indexOf("Checkbox") !== -1) {
        return "checkbox";
      } else if (type.indexOf("Date") !== -1) {
        return "date";
      } else if (type.indexOf("User link") !== -1) {
        return "user link";
      } else if (type.indexOf("Customer link") !== -1) {
        return "customer link";
      } else if (type.indexOf("Group link") !== -1) {
        return "group link";
      }
      return "text";
    },
    controlValue(prop, val, min, max, e) {
      let transVal = e.target.value;
      if (prop === "loanAmount") {
        this.fomartAndValidateLoanAmount(e);
      }
      if (e) {
        if (/[^0-9.]/g.test(e.target.value)) {
          transVal = e.target.value.replace(/([^0-9.])/g, "");
          if (transVal < min && min) {
            // this[prop] = min;
            this.alertError("Value cannot be less than " + min);
            // e.target.value = min;
          } else if (transVal > max && max) {
            this[prop] = max;
            // e.target.value = max;
            this.alertError("Value cannot be more than " + max);
          } else if (parseInt(transVal) < 0) {
            this[prop] = "";
            e.target.value = "";
          } else {
            this[prop] = transVal;
            e.target.value = transVal;
          }
        } else {
          if (transVal < min && min) {
            // this[prop] = min;
            this.alertError("Value cannot be less than " + min);
            // e.target.value = min;
          } else if (transVal > max && max) {
            // this[prop] = max;
            this.alertError("Value cannot be more than " + max);
            // e.target.value = max;
          } else if (parseInt(transVal) < 0) {
            this[prop] = "";
            e.target.value = "";
          } else {
            this[prop] = transVal;
            e.target.value = transVal;
          }
        }
      }
    },
    controlEdit(d, min, max) {
      if (d == min && min && d == max && max) {
        return true;
      }
      return false;
    },
    getLoanProducts() {
      this.$store.dispatch(GET_ALL_LOAN_PRODUCTS);
    },
    searchCustomersSingle(search) {
      const self = this;
      if (search.length) {
        self.loadingCustomers = true;
        GeneralApiService.get("Customer/search/" + search)
          .then((res) => res.data.data.items)
          .then((res) => {
            self.loadingCustomers = false;
            self.customers = res;
          })
          .catch(() => {
            self.loadingCustomers = false;
          });
      }
    },
    getAllCustomers() {
      this.allActiveCustomers = [];
      this.userLoading = true;
      const userModel = {
        pageIndex: 1,
        pageSize: 100000,
        filters: [],
        customerStatus: "Active",
      };
      GeneralApiService.post("Customer/customers", userModel).then((resp) => {
        this.allCustomers = resp.data.data.items;
      });
    },
    searchCustomers(search, index) {
      const self = this;
      if (search.length) {
        self.guarantees[index].loadingSource = true;
        GeneralApiService.get("Customer/search/" + search)
          .then((res) => res.data.data.items)
          .then((res) => {
            self.guarantees[index].loadingSource = false;
            self.guarantees[index].sourceList = res;
          })
          .catch(() => {
            self.guarantees[index].loadingSource = false;
          });
      }
    },
    getCustomer(id) {
      const self = this;
      GeneralApiService.get("Customer/" + id)
        .then((res) => res.data.data)
        .then((res) => {
          self.customer = res;
        })
        .catch(() => {});
    },
    resetWindow: function () {
      Object.assign(this.$data, EditLoanAccountInitialState());
    },
    confirmSave() {
      this.$v.form.$touch();

      //if (this.validateLoanAmount())
      //return this.alertError(
      //"Loan amount can not be smaller or larger than the loan amount specified"
      //);

      const v = this.validate();
      if (this.$v.$invalid || !v) {
        this.alertError("Some Required Fields are empty");
        if (this.$refs.accountSettings.attributes[2].value == "false") {
          this.$refs.accountSettings.click();
        }
      } else {
        if (!this.confirm) {
          this.confirm = true;
        } else {
          this.confirm = false;
          this.process = false;
        }
      }
    },
    generateSchedule() {
      this.scheduleGenerationError = null;
      const errMsg =
        "Repayment Installments is required to preview the repayment schedule";
      if (!this.repaymentInstallments) {
        this.alertError(errMsg);
        this.scheduleGenerationError = errMsg;
        this.$refs.accountSettings.click();
        return;
      }
      if (
        !this.disbursementDetails.firstRepaymentDate &&
        this.disbursementDetails.expectedDisbursementDate
      ) {
        this.scheduleGenerationError = errMsg;
        this.alertError(errMsg);
        this.$refs.disbursement.click();
        return;
      }
      const p = {
        loanProductKey: this.account_details.adminLoanProduct.loan_product_key,
        loanAmount: this.loanAmount,
        formattedLoanAmount: this.inputCurrencyFormat(this.loanAmount),
        repaymentInstallments: this.repaymentInstallments,
        interestRate: this.interestRate,
        disbursmentDate: this.disbursementDetails.expectedDisbursementDate,
        firstRepaymentDate: this.disbursementDetails.firstRepaymentDate,
        repaymentAreMadeEveryEnum: this.repaymentScheduleMethod,
        repaymentScheduleMethod: this.repaymentScheduleMethod,
        gracePeriod: Number(this.gracePeriodType),
        gracePeriodValue: this.gracePeriod,
        loanAccountProductFeesList: this.newLoanFees.map((fee) => fee.feeKey),
        //defaultPrincipalRepaymentInterval: this.defaultPrincipalRepaymentInterval,
      };

      //validate first repayment date
      const self = this;
      this.generatingSchedule = true;
      LoanApiService.patch("LoanAccount/getrepaymentschedule", p)
        .then((res) => res.data)
        .then((res) => {
          self.generatingSchedule = false;
          if (!res.status) {
            self.scheduleGenerationError = res.message;
            self.$refs.disbursement.click();
          } else {
            self.repayments = res.data;
          }
        })
        .catch((e) => {
          self.generatingSchedule = false;
          try {
            if (!e.response.data.status) {
              if (e.response.data.message.indexOf("First repayment") !== -1) {
                self.scheduleGenerationError = e.response.data.message;
                if (self.$refs.disbursement.attributes[2].value == "false") {
                  self.$refs.disbursement.click();
                }
              } else {
                self.alertError(e.response.data.message);
              }
            }
          } catch (e) {
            self.alertError(e.message);
          }
        });
    },
    save: function () {
      this.$v.form.$touch();
      if (!this.$v.$invalid) {
        this.process = true;
        let copyOfPayload = JSON.parse(JSON.stringify(this.$data));
        //
        // transform
        //
        //guarantees
        //const guaranteesTransformed = [];

        let timestamp1 = Date.parse(
          copyOfPayload.disbursementDetails.firstRepaymentDate
        );
        let timestamp2 = Date.parse(
          copyOfPayload.disbursementDetails.expectedDisbursementDate
        );

        if (isNaN(timestamp1) == false) {
          copyOfPayload.disbursementDetails.firstRepaymentDate = this.disbursementDetails.firstRepaymentDate;
        } else if (isNaN(timestamp1) == true) {
          this.alertError("First repayment date is required");
          return;
        }

        if (isNaN(timestamp2) == false) {
          copyOfPayload.disbursementDetails.expectedDisbursementDate = this.disbursementDetails.expectedDisbursementDate;
        } else if (isNaN(timestamp2) == true) {
          this.alertError("Expected disbursement date is required");
          return;
        }

        let newCollateralAssets = [];
        let newGuarantorListing = [];

        if (this.guarantees.length) {
          const guarantor = copyOfPayload.guarantees;
          const guarantorListing = guarantor.map((e) => e.actualObject);
          newGuarantorListing = Array.prototype.concat.apply(
            [],
            guarantorListing
          );
        }
        //assets
        if (this.assets.length) {
          const collateralAssets = copyOfPayload.assets;
          const collateralAssetsListing = collateralAssets.map(
            (e) => e.actualObject
          );
          newCollateralAssets = Array.prototype.concat.apply(
            [],
            collateralAssetsListing
          );
        } else {
          copyOfPayload.assets = [];
        }

        copyOfPayload.guarantees = [
          ...newGuarantorListing,
          ...newCollateralAssets,
        ];

        // if (this.isGuarantorsEnabled || this.isCollateralEnabled) {
        //   copyOfPayload.guarantees = guaranteesTransformed;
        // } else {
        //   copyOfPayload.guarantees = null;
        // }

        if (
          !copyOfPayload.disbursementDetails.expectedDisbursementDate ||
          !copyOfPayload.disbursementDetails.firstRepaymentDate
        ) {
          copyOfPayload.disbursementDetails.expectedDisbursementDate = null;
          copyOfPayload.disbursementDetails.firstRepaymentDate = null;
        }
        copyOfPayload.productFeeList = this.newLoanFees.map((fee) => fee.feeKey);

        let mergedData = { ...copyOfPayload, ...this.form };
        mergedData.accountArrearsSettings.tolerancePercentageOfOutstandingPrincipal =
          mergedData.arrearsToleranceAmount;
        mergedData.id = this.$route.params.loanAccountKey;
        mergedData.productTypeKey =
          this.account_details.adminLoanProduct.loan_product_key;
        mergedData.accountHolderKey = this.account_details.accountHolderKey;
        //mergedData.repaymentScheduleMethod = this.repaymentReschedulingMethod;
        mergedData.administrationInterestProductSetting = {
          max_interest_rate: this.loanMaxInterestRate,
          min_interest_rate: this.loanMinInterestRate,
          max_tolerance_period: this.maxTolerancePeriod,
          min_tolerance_period: this.minTolerancePeriod,
          max_tolerance_percentage_of_outsatnding_principal:
            this.maxTolerancePercentageOfOutsatndingPrincipal,
          min_tolerance_percentage_of_outsatnding_principal:
            this.minTolerancePercentageOfOutsatndingPrincipal,
        };

        //clean up payload
        delete mergedData.form;
        delete mergedData.branchList;
        delete mergedData.customers;
        delete mergedData.scheduleReady;
        delete mergedData.loadingSchedule;
        delete mergedData.loadingCustomers;
        delete mergedData.loadingGuarantorAccounts;
        delete mergedData.generatingSchedule;
        delete mergedData.guarantorAccounts;
        delete mergedData.selectedCustomer;
        delete mergedData.alert;
        delete mergedData.guaranteeModel;
        delete mergedData.collateralAssets;
        delete mergedData.collateralModel;
        delete mergedData.scheduleGenerationError;
        delete mergedData.whatPage;
        delete mergedData.showInactiveFees;
        delete mergedData.confirm;
        delete mergedData.process;
        delete mergedData.repayments;
        delete mergedData.minLoanAmount;
        delete mergedData.maxLoanAmount;
        delete mergedData.customer;
        delete mergedData.loanProduct;
        delete mergedData.uiProps;
        delete mergedData.assets;
        delete mergedData.disbursementDetails.anticipatedDisbursementDate;

        delete mergedData.bank;
        delete mergedData.employer;
        delete mergedData.allEmployers;
        LoanApiService.patch("LoanAccount/editloanaccount", mergedData)
          .then((res) => res.data)
          .then((res) => {
            this.confirm = false;
            this.process = false;
            if (res.status) {
              this.$store.dispatch(GET_LOAN_ACCOUNT_DETAILS, {
                loanAccountKey: this.$route.params.loanAccountKey,
              });
              this.viewDetails(this.$route.params.loanAccountKey);
              this.alertSuccess(res.message);
            } else {
              this.alertError(res.message);
            }
          })
          .catch((e) => {
            this.confirm = false;
            try {
              this.process = false;
              this.alertError(e.response.data.message);
            } catch (e) {
              this.alertError(
                "An unexpected Error occurred: Kindly confirm you are connected to the internet"
              );
            }
          });
      } else {
        this.confirm = false;
        this.process = false;
        this.alertError("Some Required Fields are empty");
        if (this.$refs.accountSettings.attributes[2].value == "false") {
          this.$refs.accountSettings.click();
        }
      }
    },
    viewDetails(key) {
      this.$router.push({ path: `/loan/accounts/${key}` });
    },
    getAccountDetails() {
      this.$store.dispatch(GET_LOAN_ACCOUNT_DETAILS, {
                loanAccountKey: this.$route.params.loanAccountKey,
              });
    },
    listBranches() {
      this.loadingBranchList = true;
      return branchService.get({
        branchName: null,
        branchState: "Active",
        pageIndex: 1,
        pageSize: 100,
      });
    },
    validateStringValue(val, length = 4) {
      if (val == null || val == undefined) {
        return true;
      }
      if (typeof val == "string") {
        if (val.length === 0) {
          return true;
        } else if (val.length < length) {
          this.alertError("Value must be more than " + length);
          return false;
        } else {
          return true;
        }
      }
      return false;
    },
    validateNumberValue(val) {
      if (val == null || val == undefined) {
        return true;
      }
      if (!/^[0-9]+$/.test(val)) {
        this.alertError("Value must contain numbers only");
        return false;
      }
      if (val < 1) {
        this.alertError("Value must be more than 1");
        return false;
      } else {
        return true;
      }
    },
    addCollateral() {
      //validate form
      this.collateralModel.errorAmount = false;
      this.collateralModel.errorAssetName = false;
      this.collateralModel.errorDescription = false;
      if (!this.validateStringValue(this.collateralModel.assetName, 1)) {
        this.collateralModel.errorAssetName = true;
        return false;
      }
      if (!this.validateStringValue(this.collateralModel.description)) {
        this.collateralModel.errorDescription = true;
        return false;
      }
      if (!this.validateNumberValue(this.collateralModel.amount)) {
        this.collateralModel.errorAmount = true;
        return false;
      }
      const cloneModel = JSON.parse(JSON.stringify(this.collateralModel));
      this.collateralAssets = [...this.collateralAssets, cloneModel];
      this.collateralModel.description = null;
      this.collateralModel.amount = null;
      this.collateralModel.assetName = null;
    },
    validateCollateral() {
      //validate form
      this.collateralModel.errorAmount = false;
      this.collateralModel.errorAssetName = false;
      this.collateralModel.errorDescription = false;
      if (!this.validateStringValue(this.collateralModel.assetName, 1)) {
        this.collateralModel.errorAssetName = true;
        return false;
      }
      if (!this.validateStringValue(this.collateralModel.description)) {
        this.collateralModel.errorDescription = true;
        return false;
      }
      if (!this.validateNumberValue(this.collateralModel.amount)) {
        this.collateralModel.errorAmount = true;
        return false;
      }
    },
    updateCollateralInputField(e, index, prop) {
      const copyOfCollateralAssets = [...this.collateralAssets];
      copyOfCollateralAssets[index][prop] = e.target.value;
      this.collateralAssets = copyOfCollateralAssets;
    },
    updateGuarantorField(e, index, prop) {
      //if its an action from the customers dropdown
      if (prop === "source") {
        const copyOfGuarantors = [...this.guarantees];
        copyOfGuarantors[index][prop] = e;
        this.guarantees = copyOfGuarantors;
        //search for the selected customer accounts
        if (e.hasOwnProperty("customerKey")) {
          if (e.customerKey.length) {
            //copyOfGuarantors[index].loadingAccounts = true;
            ApiService.get(
              "DepositAccount/GetDepositAccountByCustomer/" + e.customerKey
            )
              .then((res) => {
                if (res) {
                  copyOfGuarantors[index].accountList = res.data.data;
                  //copyOfGuarantors[index].loadingAccounts = false;
                }
                //copyOfGuarantors[index].loadingAccounts = false;
              })
              .catch(() => {
                copyOfGuarantors[index].loadingAccounts = false;
              });
          }
        }
      } else if (prop === "amount") {
        const copyOfGuarantors = [...this.guarantees];
        copyOfGuarantors[index][prop] = e.target.value;
        this.guarantees = copyOfGuarantors;
      } else if (prop === "account") {
        const copyOfGuarantors = [...this.guarantees];
        copyOfGuarantors[index][prop] =
          e.target.options[e.target.selectedIndex].value;
        this.guarantees = copyOfGuarantors;
      }
    },
    removeCollateralAsset(index) {
      this.collateralAssets.splice(index, 1);
    },
    removeGuarantor(index) {
      this.guarantees.splice(index, 1);
    },
    selectGuarantor() {
      this.loadingGuarantorAccounts = true;
      const self = this;
      ApiService.get(
        "DepositAccount/GetDepositAccountByCustomer/9212e96888494c83806c2abaa6dff3a5"
      )
        .then((res) => {
          if (res) {
            self.guarantorAccounts = res.data.data;
          }
          self.loadingGuarantorAccounts = false;
        })
        .catch(() => {
          self.loadingGuarantorAccounts = false;
        });
    },
    setSelectedProduct(id) {
      const p = this.products.find((item) => item.loanProductKey === id);
      this.productTypeKey = id;
      if (p) {
        this.maxLoanAmount = p.maxLoanAmount ? p.maxLoanAmount : null;
        this.minLoanAmount = p.minLoanAmount ? p.minLoanAmount : null;
        this.gracePeriod = p.defaultGracePeriod ? p.defaultGracePeriod : 0;
        this.gracePeriodType = p.gracePeriodType ? p.gracePeriodType : 0;
        //this.getCustomer(this.account_details.accountHolderKey);
        //this.repaymentPeriodCount = p.repaymentPeriodCount;
        //this.repaymentScheduleMethod = p.repaymentScheduleMethod
          //? p.repaymentScheduleMethod
          //: null;
        this.interestCalculationMethod = p.interestCalculationMethod
          ? p.interestCalculationMethod
          : null;
        this.accountArrearsSettings.tolerancePercentageOfOutstandingPrincipal =
          p.adminArrearsResponseModel
            ? p.adminArrearsResponseModel
                .defaultTolerancePercentageOfOutstandingPrincipal
              ? p.adminArrearsResponseModel
                  .defaultTolerancePercentageOfOutstandingPrincipal
              : null
            : null;
        this.accountArrearsSettings.monthlyToleranceDay =
          p.adminArrearsResponseModel
            ? p.adminArrearsResponseModel.monthlyToleranceDsay
              ? p.adminArrearsResponseModel.monthlyToleranceDsay
              : 0
            : 0;
        this.defaultFirstRepaymentDueDateOffset = p.adminArrearsResponseModel
          ? p.adminArrearsResponseModel.defaultInterestRate
            ? p.adminArrearsResponseModel
                .defaultTolerancePercentageOfOutstandingPrincipal
            : null
          : null;
      }
      // this.$v.form.$reset()
    },
    pushGuarantorForm() {
      const cloneModel = JSON.parse(JSON.stringify(this.guaranteeModel));
      this.guarantees = [...this.guarantees, cloneModel];
    },
    getBankKey(bankName) {
      if (bankName) {
        const bank = this.banks.find((item) => item.name === bankName);
        this.disbursementDetails.bankKey = bank.bankKey;
        return bank.bankKey;
      }
      return "";
    },
    updateFormFields() {
      if (this.account_details) {
        this.form.assignedBranchKey = this.account_details.assignedBranchKey;
        this.getCustomer(this.account_details.accountHolderKey);
        this.notes = this.account_details.notes;
        this.loanAmount = this.account_details.loanAmount;
        this.payOffRate = this.account_details.payOffRate
          ? this.account_details.payOffRate
          : 0;
        this.formattedLoanAmount = this.inputCurrencyFormat(
          this.account_details.loanAmount
        );
        this.repaymentScheduleMethod = this.account_details.repaymentScheduleMethod;
        this.loanName = this.account_details.loanName;
        this.repaymentInstallments = this.account_details.repaymentInstallments;
        this.interestRate = this.account_details.interestRate;
        this.disbursementDetails.expectedDisbursementDate =
          this.account_details.disbursementDetails.disbursementDate ? 
          this.account_details.disbursementDetails.disbursementDate :
          this.convertDateToDay(this.account_details.anticipatedDisbursementDetails.anticipatedDisbursementDate);
        this.disbursementDetails.firstRepaymentDate =
          this.convertDateToDay(this.account_details.anticipatedDisbursementDetails.firstRepaymentDate);
        this.disbursementDetails.bankAccountName =
          this.account_details.disbursementDetails.disbursedAccountName ?
          this.account_details.disbursementDetails.disbursedAccountName :
          this.account_details.anticipatedDisbursementDetails.anticipatedBankAccountName;
        this.disbursementDetails.bankAccountNumber =
          this.account_details.disbursementDetails.disbursedAccountNumber ?
          this.account_details.disbursementDetails.disbursedAccountNumber :
          this.account_details.anticipatedDisbursementDetails.anticipatedBankAccountNumber;
        this.getBankKey(
          this.account_details.disbursementDetails.disbursedBankName ?? this.account_details.anticipatedDisbursementDetails.anticipatedBankName
        );
        this.arrearsTolerancePeriod =
          this.account_details.arrearsTolerancePeriod;
        this.arrearsToleranceAmount =
          this.account_details.accountArrearsSettings.tolerance_percentage_of_outstanding_principal;
        this.repaymentPeriodUnit = this.account_details.repaymentPeriodunit;
        this.repaymentPeriodCount = this.account_details.repaymentPeriodCount;
        this.defaultPrincipalRepaymentInterval = this.account_details.defaultPrincipalRepaymentInterval;
        this.maxLoanAmount =
          this.account_details.adminLoanProduct.max_loan_amount;
        this.minLoanAmount =
          this.account_details.adminLoanProduct.min_loan_amount;
        this.form.accountState = this.account_details.accountState;
        this.employer = this.account_details?.employerDetails?.data;
        this.employerKey =
          this.account_details?.employerDetails?.data?.employerKey;
      }
    },
    getAccountCustomFieldSets() {
      const self = this;
      const p = {
        customFieldSetType: "LoanAccounts",
        entityLinkedKey: this.$route.params.loanAccountKey,
      };
      GeneralApiService.get(
        "CustomFields/FieldValues/" +
          p.entityLinkedKey +
          "/" +
          p.customFieldSetType
      )
        .then((res) => res.data.data.items)
        .then((res) => {
          self.uiProps.customFieldSets = res;
        });
      // GeneralApiService.post(
      // 'CustomFields/FieldSets/FieldSetType/',
      // p
      //)
      //.then((res) => res.data.data.items)
      //.then((res) => {
      //self.uiProps.customFieldSets = res;
      //});
    },
    getLoanAccountDetail() {
      const self = this;
      LoanApiService.get(
        "LoanAccount/getloanaccountdetailsbyloanaccountid/" +
          this.$route.params.loanAccountKey
      )
        .then((res) => res.data.data)
        .then((res) => {
          self.loanProduct = res.items;
          self.getLoansNotRequired(this.account_details?.loanProductFees?.loanProductFees);
          const newValue = self.loanFeesNotRequired.map(loan => loan.feeKey);
          self.getFilteredLoans(newValue);
        });
    },
    async updateGuarantorss(guarantees) {
      this.uiProps.loadingGuarantors = true;
      const res = await guarantees.map(async (item) => {
        let ob = null;

        if (item.depositAccountKey) {
          await ApiService.get(
            "DepositAccount/GetDepositAccountBySavingAccountKey/" +
              item.depositAccountKey
          ).then((res) => {
            ob = {
              guarantorKey: item.guarantorKey,
              guarantorType: "GUARANTOR",
              amount: item.amount,
              savingsAccountKey: item.savingsAccountKey,
              type: "GUARANTOR",
              source: null,
              account: null,
              accountList: res.data.data,
              sourceList: res.data.data,
              loadingSource: false,
              loadingAccounts: false,
              errorAccount: false,
              errorAmount: false,
              errorSource: false,
            };
            return ob;
          });
        }
        return ob;
      });
      const [...d] = await Promise.all(res);
      this.uiProps.loadingGuarantors = false;
      const filterNull = d.filter((item) => item !== null);
      if (filterNull.length) this.guarantees = [...this.guarantees, filterNull];
    },
    async updateGuarantors() {
      if (this.account_details.assets.length) {
        // this.collateralAsset = this.account.assets;
        const collateral = this.account_details.assets;
        this.assets = collateral.map((item) => ({
          ...item,
          actualObject: {
            amount: item.amount,
            assetName: item.assetName,
            description: "",
            guarantorKey: "",
            guarantorType: "ASSET",
            type: "ASSET",
            savingsAccountKey: "",
            guarantyKey: item.guarantyKey,
          },
        }));
      }
      if (this.account_details.guarantors.length) {
        const securities = this.account_details.guarantors;
        this.guarantees = securities.map((item) => ({
          ...item,
          depositAccountByGuarantor: item.depositAccountByGuarantor.data,
          accountList: item.depositAccountByGuarantor.data,
          savingsAccountKey: item.depositAccountKey,
          actualObject: {
            savingsAccountKey: item.depositAccountKey,
            type: "GUARANTOR",
            guarantorKey: item.guarantorKey,
            guarantorType: "GUARANTOR",
            amount: item.amount,
            description: "",
            assetName: "",
            guarantyKey: item.guarantyKey,
          },
          source: this.getCustomerDetails(
            item.depositAccountKey,
            item.guarantorKey
          ),
        }));
      }
    },
    async getCustomerDetails(item, key) {
      let customer = {};

      await GeneralApiService.get(`Customer/${key}`).then((resp) => {
        customer = resp.data.data;
      });

      const sourceItem = this.account_details.guarantors.findIndex(
        (i) => i.depositAccountKey === item
      );

      this.guarantees[sourceItem].source = {
        ...customer,
        customerFullName:
          customer.customerFirstName + " " + customer.customerLastName,
      };
    },
    async getAllUsers() {
      this.allUsers = [];
      try {
        this.userLoading = true;
        const userModel = {
          pageNumber: 1,
          pageSize: 1000000,
          branchKey: "",
          status: true,
          filters: [],
        };
        ApiService.post(
          "ProleanUser/GetUsersWithoutPermission",
          userModel
        ).then((resp) => {
          this.allUsers = resp.data.data;
          this.userLoading = false;
        });
      } catch (e) {
        const error = e.response.data.message;
        this.userLoading = false;
        this.$emit("alertError", error);
      }
    },
  },
  watch: {
    productTypeKey: function (id) {
      this.setSelectedProduct(id);
    },
    products(change) {
      if (change) {
        this.loanProduct = change.find(
          (item) =>
            item.loanProductKey ==
            this.account_details.adminLoanProduct.loan_product_key
        );
       this.setSelectedProduct(
          this.account_details.adminLoanProduct.loan_product_key
        );
        this.$v.$reset();
      }
    },
    "disbursementDetails.firstRepaymentDate": function () {
      this.scheduleGenerationError = null;
    },
    "$route.name"(routeName) {
      if (routeName !== "edit_loan_account") return;
      const { id } = this.$route.params;
      this.setSelectedProduct(id);
    },
  },
  computed: {
    ...mapState({
      guarantorsTotalAmount: function () {
        let total = 0;
        this.guarantees.map((item) => {
          total += parseInt(item.amount);
        });
        return total;
      },
      loading: (state) => state.loan.loading_all,
      account_details: (state) =>
        state.loan.account_details ? state.loan.account_details.data : null,
      products: (state) =>
        state.loan.products_all
          ? state.loan.products_all.data
            ? state.loan.products_all.data
            : []
          : [],
      saving: (state) => state.loan.saving,
      banks: (state) => state.banking.allActiveBanks,
      loanProductDescription() {
        return this.account_details
          ? this.account_details.adminLoanProduct
            ? this.account_details.adminLoanProduct.product_description
            : null
          : null;
      },
      loanRepaymentScheduleMethod() {
        return this.loanProduct
          ? this.loanProduct.repaymentScheduleMethod
          : null;
      },
      loanMinInterestRate() {
        return this.loanProduct
          ? this.loanProduct.adminInterestProductSettings
            ? this.loanProduct.adminInterestProductSettings.minInterestRate
            : null
          : null;
      },
      loanProductName() {
        return this.account_details
          ? this.account_details.adminLoanProduct
            ? this.account_details.adminLoanProduct.product_name
            : null
          : null;
      },
      loanMaxInterestRate() {
        return this.loanProduct
          ? this.loanProduct.adminInterestProductSettings
            ? this.loanProduct.adminInterestProductSettings.maxInterestRate
            : null
          : null;
      },
      maxNumInstallments() {
        return this.loanProduct
          ? this.loanProduct.maxNumInstallments
            ? this.loanProduct.maxNumInstallments
            : 0
          : 0;
      },
      minNumInstallments() {
        return this.loanProduct
          ? this.loanProduct.minNumInstallments
            ? this.loanProduct.minNumInstallments
            : 0
          : 0;
      },
      minFirstRepaymentDueDateOffset() {
        return this.loanProduct
          ? this.loanProduct.minFirstRepaymentDueDateOffset
            ? this.loanProduct.minFirstRepaymentDueDateOffset
            : null
          : null;
      },
      maxFirstRepaymentDueDateOffset() {
        return this.loanProduct
          ? this.loanProduct.maxFirstRepaymentDueDateOffset
            ? this.loanProduct.maxFirstRepaymentDueDateOffset
            : null
          : null;
      },
      maxTolerancePercentageOfOutsatndingPrincipal() {
        return this.account_details
          ? this.account_details.adminLoanProduct
            ? this.account_details.adminLoanProduct
                .administrationProductArrearsSetting
              ? this.account_details.adminLoanProduct
                  .administrationProductArrearsSetting
                  .max_tolerance_percentage_of_outsatnding_principal
              : null
            : null
          : null;
      },
      minTolerancePercentageOfOutsatndingPrincipal() {
        return this.account_details
          ? this.account_details.adminLoanProduct
            ? this.account_details.adminLoanProduct
                .administrationProductArrearsSetting
              ? this.account_details.adminLoanProduct
                  .administrationProductArrearsSetting
                  .min_tolerance_percentage_of_outsatnding_principal
              : null
            : null
          : null;
      },
      loanProductType() {
        return this.account_details
          ? this.account_details.adminLoanProduct
            ? this.account_details.adminLoanProduct.loan_product_type.replace(
                /_/g,
                " "
              )
            : null
          : null;
      },
      loanGracePeriodTypeId() {
        return this.loanProduct ? this.loanProduct.gracePeriodType > 0 : null;
      },
      isGuarantorsEnabled() {
        return this.loanProduct
          ? this.loanProduct.adminProductSecuritySetting.isGuarantorsEnabled > 0
          : null;
      },
      isCollateralEnabled() {
        return this.loanProduct
          ? this.loanProduct.adminProductSecuritySetting.isCollateralEnabled > 0
          : null;
      },
      loanGracePeriodTypeLabel(state) {
        if (this.loanProduct) {
          const hh = state.loan.enums.gracePeriod.find(
            (h) => h.id == this.loanProduct.gracePeriodType
          );
          return hh.name;
        }
      },
      loanInterestType(state) {
        if (this.account_details) {
          const hh = state.loan.enums.repaymentAreMadeEvery.find(
            (h) => h.id == this.account_details.repaymentScheduleMethod
          );
          return hh.name;
        }
      },
      loanInterestCalculationMethod(state) {
        const hh = state.loan.enums.interestCalculationMethod.find(
          (h) =>
            h.id ==
            this.account_details.adminLoanProduct.interest_calculation_method
        );
        return hh.name;
      },
      accountRecipient() {
        if (this.customer) {
          const customerFullName = this.customer.customerFirstName +
            " " +
            this.customer.customerLastName;
          return this.customer.customerFullName ? this.customer.customerFullName : customerFullName;
        }
        //if (this.customer) {
          //if (this.customer.customerFullName) {
            //return this.customer.customerFullName;
          //} else {
            //return (
              //this.customer.customerFirstName +
              //" " +
              //this.customer.customerLastName
            //);
          //}
        //}
        return "";
      },
      repaymentAreMadeEvery(state) {
        const self = this;
        return state.loan.enums.repaymentAreMadeEvery.filter((unit) => {
          if (unit.id == 0 || unit.id == 1) return true;
          if ((unit.id == 2 || unit.id == 3) && self.daysInYear == 2) {
            return true;
          } else {
            return false;
          }
        });
      },
      anticipatedDisbursementDateIsReq() {
        return !this.disbursementDetails.expectedDisbursementDate;
      },
      expectedClosure() {
        if (this.repayments) {
          if (Array.isArray(this.repayments)) {
            if (this.repayments.length) {
              return this.repayments[this.repayments.length - 1]
                .installmentDate;
            }
          }
        }
        return null;
      },
    }),
    sortedBanks() {
      const banksArray = this.banks;
      return banksArray.sort((a, b) => a.name.localeCompare(b.name));
    },
    sortedCustomers() {
      const newArr = this.allCustomers.filter(
        (item) => item.customerStatus === "Active"
      );
      const cusArray = newArr.map((item) => {
        return {
          customerKey: item.customerKey,
          assignedBranchKey: item.assignedBranchKey,
          assignedCentreKey: item.assignedCentreKey,
          assignedUserKey: item.assignedUserKey,
          branchAssigned: item.branchAssigned,
          bvn: item.bvn,
          customerTypesKey: item.customerTypesKey,
          customerFullName:
            item.customerFirstName + " " + item.customerLastName,
        };
      });
      return cusArray;
      //return userArray.sort((a, b) => a.customerFullName.localeCompare(b.customerFullName));
    },
    isAccountInPartialApplication() {
      return (
        this.account_details.accountState.toLowerCase() == "partial application"
      );
    },
    isAccountActive() {
      return this.account_details.accountState.toLowerCase() == "active";
    },
    isAccountIsPending() {
      return (
        this.account_details.accountState.toLowerCase() == "pending approval"
      );
    },
    sortedEmployers() {
      const employerArray = this.allEmployers;
      return employerArray.sort((a, b) => a.name.localeCompare(b.name));
    },
    sortedUsers() {
      const userArray = this.allUsers;
      return userArray.sort((a, b) => a.name.localeCompare(b.name));
    },
    maxTolerancePeriod() {
      return this.account_details
        ? this.account_details.adminLoanProduct
          ? this.account_details.adminLoanProduct
              .administrationProductArrearsSetting
            ? this.account_details.adminLoanProduct
                .administrationProductArrearsSetting.max_tolerance_period
            : null
          : null
        : null;
    },
    minTolerancePeriod() {
      return this.account_details
        ? this.account_details.adminLoanProduct
          ? this.account_details.adminLoanProduct
              .administrationProductArrearsSetting
            ? this.account_details.adminLoanProduct
                .administrationProductArrearsSetting.min_tolerance_period
            : null
          : null
        : null;
    },
    newRepayments() {
      if (this.repayments.repayments) {
        const newRep = this.repayments.repayments;
        newRep.shift();
        return this.repayments.repayments;
      }
      return null;
    },
    loanHasFees() {
      const { loanProductFees } = this.account_details;
      return loanProductFees.loanProductFees
        ? loanProductFees.loanProductFees.length > 0
        : false;
    },
    requiredLoanFees() {
      const { loanProductFees } = this.account_details;
      const unsortedRequired = 
        loanProductFees.loanProductFees.filter(
          (fee) => fee.isRequired === true
        );
      const sortedRequired = unsortedRequired.sort((firstEl, secondEl) => {
        const firstTitle = firstEl.feeName.toLowerCase();
        const secondTitle = secondEl.feeName.toLowerCase();

        return firstTitle < secondTitle ? -1 : firstTitle > secondTitle ? 1 : 0;
      });
      return sortedRequired ?? null;
    },
    loanFeesNotRequired() {
      const { loanProductFees } = this.account_details;
      const unsortedLoanFees = 
        loanProductFees.loanProductFees.filter(
          (fee) => fee.isRequired !== true
        ) ?? null;
      const sortedRequired = unsortedLoanFees.sort((firstEl, secondEl) => {
        const firstTitle = firstEl.feeName.toLowerCase();
        const secondTitle = secondEl.feeName.toLowerCase();

        return firstTitle < secondTitle ? -1 : firstTitle > secondTitle ? 1 : 0;
      });
      return sortedRequired;
    },
    totalLoansNotRequired() {
      return this.loanFeesNotRequired.length;
    },
    getFeeDifference() {
      return this.loanFeesNotRequired.length - this.newLoanFees.length === 0;
    },
  },
  filters: {
    moment: function (date) {
      return date != null ? moment(date).format("Do-MMM-YYYY") : "";
    },
  },
  created() {
    this.getAllCustomers();
    this.getAccountCustomFieldSets();
    this.getAccountDetails();
  },
  mounted() {
    const self = this;
    this.listBranches().then((res) => {
      self.loadingBranchList = false;
      if (res.status === 200) {
        self.branchList = res.data.data.items;
        this.getAccountOfficer();
      }
    });
    this.getLoanAccountDetail();
    //this.getAllCustomers();
    this.getAllBanks();
    this.getAllUsers();
    this.updateFormFields();
    this.getAllEmployers();
    this.form.assignedUserKey = this.account_details.accountOfficer.user_key;
    //this.disbursementDetails = this.account_details.disbursementDetails;
    this.setSelectedProduct(this.$route.params.loanAccountKey);
    this.getLoanProducts();
    //this.getAccountCustomFieldSets();
    this.updateGuarantors();
    //load assets
    if (this.account_details.assets) {
      if (Array.isArray(this.account_details.assets)) {
        if (this.account_details.assets.length) {
          this.account_details.assets.forEach((item) => {
            this.collateralAssets.push({
              assetName: item.assetName,
              description: null,
              amount: item.amount,
              errorDescription: false,
              errorAssetName: false,
              errorAmount: false,
            });
          });
        }
      }
    }
    this.formattedLoanAmount = this.inputCurrencyFormat(
      this.account_details.loanAmount
    );

    //this.getLoanAccountDetail();
  },
};
</script>
<style lang="scss" scoped>
.modal__head__subtitle {
  font-weight: 600;
  font-family: "Montserrat-semibold";
  font-size: 16px;
  line-height: 28px;
  color: #1e1f20;
}

.modal__head__subtitled {
  font-weight: 600;
  font-family: "Montserrat-semibold";
  font-size: 16px;
  line-height: 28px;
  color: #1e1f20;
}
</style>
